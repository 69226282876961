var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard"},[(!_vm.evereeContractors || !Array.isArray(_vm.evereeContractors))?_c('Loader'):_vm._e(),_c('div',{staticClass:"dashboard__container"},[_vm._m(0),_c('div',{staticClass:"dashboard__container--body"},[(_vm.evereeContractors && _vm.evereeContractors.length >= 1)?_c('div',{staticClass:"mb-5 w100"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.evereeContractors,"styleClass":"vgt-table condensed","search-options":{
            enabled: true,
            placeholder: 'Search this table',
          },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: 25,
          }},on:{"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'bankAccounts')?_c('span',[(props.row.bankAccounts && props.row.bankAccounts[0])?_c('span',[_vm._v(_vm._s(props.row.bankAccounts[0].bankName))]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,1765130879)})],1):_vm._e()])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard__container--header"},[_c('h1',[_vm._v("Everee Contractors")])])}]

export { render, staticRenderFns }